<!--提款历史-->
<template>
  <div class="drawinghistory">
    <p style="text-align: left;font-size: 30px">{{$t('Withdrawal.History')}}</p>
    <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <!--    所属分公司  -->
      <el-table-column :label="$t('sys.common.companyCode')" prop="companyCode" align="center" />
      <!--    申请单号  -->
      <el-table-column :label="$t('miMemberApalyCheck.askForNo_es_ES')" prop="applNo" align="center" />
      <!--      用户编号-->
      <el-table-column :label="$t('fiPayAdvice.userCode')" prop="userCode" align="center" />
      <!--      申请金额-->
      <el-table-column :label="$t('member.sqje')" prop="amount" align="center" />
      <!--      综合管理税费-->
      <el-table-column :label="$t('fi.tax.multi')" prop="fees" align="center" />
      <!--      实发金额   -->
       <el-table-column :label="$t('fiBonusAppl.sendAmt')" prop="sendAmt" align="center" />
      <!--      币种-->
<!--      <el-table-column :label="$t('fiShopAcTemp.currencyCode')" prop="" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          {{ row.localCurrency }}-->
<!--        </template>-->
<!--      </el-table-column>-->
      <!--      汇率-->
      <el-table-column :label="$t('bdNetWorkCostReport.rateCH')" prop="rate" align="center" />
<!--      本年度超出额度-->
<!--      <el-table-column :label="$t('Exceeded_quota_this_year')" prop="applLimit" align="center" />-->
      <!--      本地货币金额-->
<!--      <el-table-column :label="$t('fiBankbookTemp.localMoney')" prop="localMoney" align="center" />-->
      <!--      申请时间-->
      <el-table-column :label="$t('miMemberApply.createTime')" prop="createTime" align="center" />
      <!--     申请状态 -->
      <el-table-column :label="$t('miMemberApply.status')" prop="" align="center">
        <template v-slot="{row}">
          <div v-if="row.status === '1' && row.reCheckerCode !== ''" style="color: blue">{{ $t('poLpOrder.check.0') }}</div>
          <div v-else-if="row.status === '1' && row.reCheckerCode === ''" style="color: blue">{{ $lt(initDict.acapplstatuscn, row.status) }}</div>
          <div v-else-if="row.status === '3'" style="color: green">{{ $lt(initDict.acapplstatuscn,row.status) }}</div>
          <div v-else-if="row.status === '3' || row.status === '6' || row.status ==='9' " style="color: red">{{ $lt(initDict.acapplstatuscn, row.status) }}</div>
          <div v-else>{{ $lt(initDict.acapplstatuscn,row.status) }}</div>
        </template>
      </el-table-column>
      <!--     审核人编号 -->
<!--      <el-table-column :label="$t('csServiceCenter.checkerCode')" prop="checkerCode" align="center" />-->
      <!--      审核时间-->
<!--      <el-table-column :label="$t('csServiceCenter.checkTime')" prop="checkTime" align="center" />-->
    </el-table>
    <!--    分页-->
    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getList"
    />
    <span style="float: right">
      <el-button size="mini" type="warning" @click="continueBtn" plain>{{$t('btn.continue')}}</el-button>
    </span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getFiAcAppls} from "@/api/rus/api";
import Pagination from '@/components/Pagination'
export default {
  name: "DrawingHistory",
  components: { Pagination },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20
      },
      tableKey:0,
      list: [],
      doLoading: false,
      total: 0,
      btnloading:false,
      listLoading:false,
      initDict:{
        acapplstatuscn:'fi_ac_appl_status_cn'
      }
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
    ]),
  },
  created() {
    this.getList()
  },
  methods: {
    getList(){
      this.listLoading = true
      this.listQuery.userCode = this.userCode
      getFiAcAppls({...this.listQuery}).then(res=>{
        this.list = res.data.records
        this.total = +res.data.total
        this.listLoading = false
      }).catch(err=>{
        this.listLoading = false
        console.error(err)
      })
    },
    continueBtn(){
      this.listQuery.page = 1
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
